export const getSubdomain = (): string => {
    const hostName = window.location.hostname;
    const domainMatches = hostName.match(/hero-centre|herocentre|hero-center|herocenter/g);
    const parts = hostName.split('.');
    let subdomain = parts[0] || '';
    const defaultSubdomain = 'herocenter';
    const testIndex = subdomain.indexOf('-test');
    const isTest = testIndex >= 0;
    const demoIndex = subdomain.indexOf('-demo');
    const isDemo = demoIndex >= 0;

    if (!domainMatches) {
        // no domain found
        subdomain = defaultSubdomain;
    } else {
        const domainSameAsFirstPart = domainMatches.find((domain) => domain === subdomain);
        if (domainSameAsFirstPart) {
            // domain is same as subdomain
            subdomain = defaultSubdomain;
        }

        if (subdomain === 'www' || subdomain === 'demo') {
            // domain is demo
            subdomain = defaultSubdomain;
        }

        if (isTest) {
            // subdomain has test
            subdomain = subdomain.substring(0, testIndex);
        }

        if (isDemo) {
            // subdomain has demo
            subdomain = subdomain.substring(0, demoIndex);
        }
    }

    return subdomain;
};
