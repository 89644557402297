import _ from 'lodash';
import React, { FunctionComponent, useEffect, useState } from 'react';
import useAxiosFetch from '../../../hooks/useAxiosFetch';
import { Tenant } from '../../../models/Tenant';
import Tenants from './Tenants/Tenants';
import classes from './TenantsManagement.module.scss';
import TrashedTenants from './TrashedTenants/TrashedTenants';

const TenantsManagement: FunctionComponent = () => {
    const { data: dataTenants, loading: loadingTenants } = useAxiosFetch<Tenant[]>({ url: `tenants` });

    const { data: dataTrashedTenants, loading: loadingTrashedTenants } = useAxiosFetch<Tenant[]>({
        url: `tenants/trashed`,
    });

    const [tenants, setTenants] = useState<Tenant[]>([]);
    const [trashedTenants, setTrashedTenants] = useState<Tenant[]>([]);

    useEffect(() => {
        if (dataTenants) {
            setTenants(dataTenants);
        }
    }, [dataTenants]);

    useEffect(() => {
        if (dataTrashedTenants) {
            setTrashedTenants(dataTrashedTenants);
        }
    }, [dataTrashedTenants]);

    const onTenantsUpdateHandler = (tenants: Tenant[]) => {
        setTenants(tenants);
    };

    const onTenantRestoredHandler = (tenant: Tenant) => {
        onTenantPermanentlyDeletedHandler(tenant);

        const newTenants = _.cloneDeep(tenants);

        if (newTenants.findIndex((t) => t.id === tenant.id) === -1) {
            newTenants.push(tenant);
        }

        setTenants(newTenants);
    };

    const onTenantPermanentlyDeletedHandler = (tenant: Tenant) => {
        const newTrashedTenants = _.cloneDeep(trashedTenants);

        newTrashedTenants.splice(
            newTrashedTenants.findIndex((t) => t.id === tenant.id),
            1
        );

        setTrashedTenants(newTrashedTenants);
    };

    return (
        <div className={classes.TenantsManagement}>
            <Tenants
                tenants={tenants}
                loading={loadingTenants}
                onTenantsUpdateHandler={onTenantsUpdateHandler}
            />

            <TrashedTenants
                tenants={trashedTenants}
                loading={loadingTrashedTenants}
                onTenantRestoredHandler={onTenantRestoredHandler}
                onTenantPermanentlyDeleteHandler={onTenantPermanentlyDeletedHandler}
            />
        </div>
    );
};

export default TenantsManagement;
