import { DialogTitle, DialogContent, DialogActions } from '@mui/material';
import React, { FunctionComponent } from 'react';
import Button from '../../Button/Button';
import InputField from '../../InputField/InputField';
import EmailInput from '../EmailInput/EmailInput';
// import EmailInput from '../EmailInput/EmailInput';
import classes from './TenantForm.module.scss';

interface ITenantFormProps {
    closeDialogHandler: () => void;
    handleFormSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
    disabledSubmit: boolean;
}

const TenantForm: FunctionComponent<ITenantFormProps> = ({ closeDialogHandler, handleFormSubmit, disabledSubmit }) => {
    return (
        <form
            onSubmit={handleFormSubmit}
            className={classes.NewTenantDialog}
        >
            <DialogTitle>Make new tenant</DialogTitle>
            <DialogContent>
                <div className={classes.DialogContent}>
                    <InputField
                        autoFocus
                        name="id"
                        label="Tenant ID (Only letters and numbers allowed)"
                        type="text"
                        // value={formik.values.id}
                        // onChange={formik.handleChange}
                        // error={formik.touched.id && Boolean(formik.errors.id)}
                        // helperText={formik.touched.id && formik.errors.id}
                    />
                    <InputField
                        name="name"
                        label="Name"
                        type="text"
                        // value={formik.values.name}
                        // onChange={formik.handleChange}
                        // error={formik.touched.name && Boolean(formik.errors.name)}
                        // helperText={formik.touched.name && formik.errors.name}
                    />
                    <EmailInput />
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    text="Cancel"
                    // alt
                    border
                    onClick={closeDialogHandler}
                ></Button>
                <Button
                    text="Make tenant"
                    type="submit"
                    disabled={disabledSubmit}
                ></Button>
            </DialogActions>
        </form>
    );
};

export default TenantForm;
