import { FormikHelpers } from 'formik';
import { useConfig } from '../../context/ConfigContext';
import axiosInstance from '../../utils/axiosInstance';

export interface IFormFields {
    id: string;
    name: string;
    emails: [];
}

export type ReturnType = {
    handleSubmit: (formFields: IFormFields, actions: FormikHelpers<IFormFields>) => Promise<void>;
    initialValues: IFormFields;
};

const useNewTenantForm = (initialValues: IFormFields): ReturnType => {
    const { config } = useConfig();

    const handleSubmit = (formFields: IFormFields, actions: FormikHelpers<IFormFields>) => {
        const data = {
            ...formFields,
            emails: JSON.stringify(formFields.emails.map((e) => e)),
        };

        return axiosInstance.post(`${config.baseUrl}/tenants`, data).then(() => {
            actions.resetForm();
        });
    };

    return {
        handleSubmit,
        initialValues,
    };
};

export default useNewTenantForm;
