import { createCtx } from './createCtx';

export type Config = {
    baseUrl: string;
};

export type ConfigContextType = {
    config: Config;
};

export const [useConfig, CtxProvider] = createCtx<ConfigContextType>();
