import { Dialog } from '@mui/material';
import { Formik } from 'formik';
import React, { FunctionComponent } from 'react';
import * as Yup from 'yup';
import { BaseTenant } from '../../models/Tenant';
import TenantForm from './TenantForm/TenantForm';
import useNewTenantForm from './useNewTenantForm';

interface NewTenantDialogProps {
    open: boolean;
    closeClickHandler: () => void;
    newTenantHandler: (tenant: BaseTenant) => void;
}

export interface IFormFields {
    id: string;
    name: string;
    emails: [];
}

const NewTenantDialog: FunctionComponent<NewTenantDialogProps> = (props) => {
    const { initialValues, handleSubmit } = useNewTenantForm({
        id: '',
        name: '',
        emails: [],
    });

    const schema = Yup.object({
        id: Yup.string()
            .min(2, 'Minimum of 2 characters')
            .required('ID is required')
            .matches(/^[aA-zZ0-9]+$/, 'Only letters and numbers'),
        name: Yup.string()
            .min(2, 'Minimum of 2 characters')
            .required('Name is required')
            .matches(/^[aA-zZ0-9\s]+$/, 'Only letters, numbers and spaces'),
    });

    return (
        <Dialog
            open={props.open}
            onClose={props.closeClickHandler}
        >
            <Formik
                onSubmit={(formValues, actions) => {
                    handleSubmit(formValues, actions);
                    props.newTenantHandler(formValues);
                }}
                initialValues={initialValues}
                validationSchema={schema}
            >
                {({ dirty, isValid, isSubmitting, handleSubmit }) => {
                    const disabledSubmit = isSubmitting || !(isValid && dirty);
                    return (
                        <TenantForm
                            handleFormSubmit={handleSubmit}
                            closeDialogHandler={props.closeClickHandler}
                            disabledSubmit={disabledSubmit}
                        />
                    );
                }}
            </Formik>
        </Dialog>
    );
};

export default NewTenantDialog;
