import { IUser } from '../models/User';
import { createCtx } from './createCtx';

export type Auth = {
    isLoggedIn: boolean;
    currentUser: IUser | undefined;
};

export type AuthContextType = {
    auth: Auth;
    setAuth: (auth: Auth) => void;
    checkSession: () => void;
    autoLogin: (user: IUser) => void;
    logout: () => void;
};

export const [useAuth, CtxProvider] = createCtx<AuthContextType>();
