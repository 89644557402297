import Axios from 'axios';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useAuth } from '../../context/AuthContext';
import { useConfig } from '../../context/ConfigContext';
import AxiosInstance from '../../utils/axiosInstance';
import LoginForm from './LoginForm/LoginForm';

export interface LoginData {
    forceLogout: boolean;
}
export interface LoginLocation {
    data?: LoginData;
}

const Login: FunctionComponent = () => {
    const { config } = useConfig();
    const { checkSession, logout: AuthLogout } = useAuth();
    const location = useLocation();
    const [loginData, setLoginData] = useState<LoginData | undefined>();

    useEffect(() => {
        localStorage.clear();
        checkSession();
        // console.log('logout and clear on login', isLoggedIn);
    }, [checkSession]);

    useEffect(() => {
        const locationState = location.state as LoginLocation;
        if (locationState?.data) {
            setLoginData(locationState.data);
        }
    }, [location]);

    useEffect(() => {
        const source = Axios.CancelToken.source();

        if (loginData && loginData.forceLogout) {
            const logout = async () => {
                try {
                    await AxiosInstance.post(`${config.baseUrl}/logout`, { cancelToken: source?.token });
                } catch (error) {
                    if (!Axios.isCancel(error)) {
                        throw error;
                    }
                }
            };

            logout();

            localStorage.clear();
            AuthLogout();
        }

        return () => {
            source.cancel();
        };
    }, [loginData, AuthLogout, config.baseUrl]);

    return <LoginForm></LoginForm>;
};

export default Login;
