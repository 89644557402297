import React, { FunctionComponent } from 'react';
import classes from './CharactersLeft.module.scss';

type CharactersLeftProps = {
    current: number;
    max: string | number;
};

const CharactersLeft: FunctionComponent<CharactersLeftProps> = ({ max, current }) => {
    const left = +max - current;
    const hasExceeded = left === 0;
    const hasExceededClass = hasExceeded ? classes.hasExceeded : '';
    return <div className={`${classes.CharactersLeft} ${hasExceededClass}`}>{`${hasExceeded ? '0' : ''}${hasExceeded ? '' : left}`}</div>;
};

export default CharactersLeft;
