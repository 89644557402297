import React, { FunctionComponent, ReactNode, useEffect, useRef } from 'react';
import { useInterceptors } from '../utils/axiosInstance';
import { getSubdomain } from '../utils/domain';
import { Config, CtxProvider } from './ConfigContext';

export const getConfig = (): { baseUrl: string; subdomain: string } => {
    const subdomain = getSubdomain();
    // const tenant = subdomain;
    // Subdomain added to baseUrl within Axios instance.
    const baseUrl = '/admin'; // addTenantToBaseUrl(tenant);
    return { baseUrl, subdomain };
};

type Props = {
    children?: ReactNode;
};

export const ConfigProvider: FunctionComponent<Props> = ({ children }) => {
    const { initInterceptors } = useInterceptors();
    const runOnce = useRef(true);

    const defaultConfig: Config = {
        baseUrl: '/admin',
    };

    const config = defaultConfig;

    useEffect(() => {
        if (runOnce.current) {
            runOnce.current = false;

            initInterceptors();
        }
    });

    return <CtxProvider value={{ config }}>{children}</CtxProvider>;
};
