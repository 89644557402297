import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import React, { FunctionComponent, ReactNode, useState } from 'react';
import { StyleData } from '../models/Theme';

// import useAxiosFetch from '../hooks/useAxiosFetch';
// import { useConfig } from './ConfigContext';
import { CtxProvider } from './ThemeContext';
import { getColor } from '../utils/colors';

type ThemeProviderProps = {
    children?: ReactNode;
    overrides?: Partial<StyleData>;
};

export const ThemeProvider: FunctionComponent<ThemeProviderProps> = ({ children, overrides }) => {
    const defaultTheme: StyleData = {
        id: 0,
        name: '',
        css: '',
        active: false,
    };
    const [theme, setTheme] = useState<StyleData>({
        ...defaultTheme,
        ...overrides,
    });

    const MuiTheme = createTheme({
        typography: {
            fontFamily: getColor('--header-font-family'),
        },
        palette: {
            error: {
                main: getColor('--brand-danger-color'),
            },
            warning: {
                main: getColor('--brand-alert-color'),
            },
            info: {
                main: getColor('--brand-color-5'),
            },
            success: {
                main: getColor('--brand-color-4'),
            },
            primary: {
                main: `rgb(${getColor('--brand-color-5-rgb')})`,
            },
            text: {
                primary: getColor('--text-color'),
            },
        },
        components: {
            MuiPaper: {
                variants: [
                    {
                        props: { variant: 'navigation' },
                        style: {
                            backgroundColor: getColor('--brand-color-11'),
                        },
                    },
                ],
            },
        },
    });

    return (
        <CtxProvider value={{ theme, setTheme }}>
            <MuiThemeProvider theme={MuiTheme}>{children}</MuiThemeProvider>
        </CtxProvider>
    );
};
