import Axios, { AxiosResponse, CancelTokenSource } from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useConfig } from '../context/ConfigContext';
import axiosInstance from '../utils/axiosInstance';

export interface AxiosFetchResponse<T> {
    request: Promise<AxiosResponse<T, any>> | undefined;
    response: AxiosResponse<T> | null;
    loading: boolean;
    method?: string;
    data: T | undefined;
    sendRequest: () => Promise<void>;
}

export interface AxiosFetchProps {
    url: string | null;
    method?: string;
    body?: any;
}

const useAxiosFetch = <T>(props: AxiosFetchProps): AxiosFetchResponse<T> => {
    const { config } = useConfig();
    const [response, setResponse] = useState<AxiosResponse<T> | null>(null);
    const [loading, setLoading] = useState(true);
    const source = useRef<CancelTokenSource>();
    const [request, setRequest] = useState<Promise<AxiosResponse<T, any>>>();
    const [data, setData] = useState<T>();
    const unmounted = useRef<boolean>(false);

    const sendRequest = useCallback(() => {
        source.current = Axios.CancelToken.source();

        const url = props.url;

        if (url !== null) {
            const getData = async () => {
                try {
                    let request: Promise<AxiosResponse<T, any>>;
                    let data: T;

                    if (props.method === 'post') {
                        request = axiosInstance.post<T>(`${config.baseUrl}/${url}`, { cancelToken: source.current?.token });
                    } else {
                        request = axiosInstance.get<T>(`${config.baseUrl}/${url}`, {
                            cancelToken: source.current?.token,
                        });
                    }
                    setRequest(request);

                    request.then((r) => {
                        if (r && !unmounted.current) {
                            data = r.data;
                            setData(data);
                            setResponse(r);
                            setLoading(false);
                        }
                    });

                    // setResponse(data);
                    setLoading(false);
                } catch (e) {
                    setResponse(null);
                    setLoading(false);
                }
            };

            return getData();
        }

        source.current.cancel('Cancelling in cleanup');

        return Promise.resolve();
    }, [config.baseUrl, props.url, props.method]);

    useEffect(() => {
        if (props.url !== null) {
            sendRequest();
        } else {
            setResponse(null);
            setLoading(false);
        }
        return () => {
            if (source.current) {
                source.current.cancel('Cancelling useAxiosFetch');
            }
        };
    }, [props.url, sendRequest]);

    return { loading, request, response, data, sendRequest };
};

export default useAxiosFetch;
