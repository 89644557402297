import Axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';

export const api_scheme = process.env.REACT_APP_ENV === 'local' ? 'http' : 'https';
const axiosInstance = Axios.create({
    baseURL: `${api_scheme}://${process.env.REACT_APP_API_DOMAIN}/`,
});

export const useInterceptors = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const gotoLoginAndForceLogout = (): void => {
        navigate('/login', { replace: true, state: { data: { forecLogout: true } } });
    };

    const initInterceptors = () => {
        axiosInstance.interceptors.request.use(
            (requestConfig) => {
                return Promise.resolve(requestConfig);
            },
            (error) => {
                return Promise.reject(error);
            }
        );

        axiosInstance.interceptors.response.use(
            (response) => {
                return Promise.resolve(response);
            },
            (error) => {
                if (error.message === 'Network Error' && !error.response) {
                    navigate(location.pathname, {
                        state: {
                            errorStatusCode: 500,
                        },
                    });
                    return Promise.reject(error);
                }

                if (error.message === undefined && !error.response) {
                    return Promise.reject(error);
                }

                if (error.response) {
                    const { status, data } = error?.response as { status: number; statusText: string; data: any };

                    if (data) {
                        const { error } = data as {
                            error: string;
                            error_description: string;
                            hint: string;
                            message: string;
                        };
                        if (error === 'invalid_request') {
                            gotoLoginAndForceLogout();
                            return Promise.reject(error);
                        }
                    }

                    // 401
                    if (status === 401) {
                        gotoLoginAndForceLogout();
                        return Promise.reject(error);
                    }

                    // 403
                    if (status === 403) {
                        // gotoLoginAndForceLogout();
                        return Promise.reject(error);
                    }

                    if (status === 404) {
                        console.log(404);
                        navigate('/', { replace: true });
                        return Promise.reject(error);
                    }

                    if (status === 422) {
                        return Promise.reject(error);
                    }

                    navigate(location.pathname, {
                        state: {
                            errorStatusCode: status,
                        },
                    });
                    return Promise.reject(error);
                }
            }
        );
    };

    return { initInterceptors, axiosInstance };
};

export default axiosInstance;
