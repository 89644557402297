import { Formik } from 'formik';
import React, { FunctionComponent, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';
import * as Yup from 'yup';
import { useConfig } from '../../../context/ConfigContext';
import { IUser } from '../../../models/User';
import Axios from '../../../utils/axiosInstance';
import { setLocalStorageToken } from '../../../utils/flavourstorage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import classes from './LoginForm.module.scss';
import BoxHeader from '../../../components/BoxHeader/BoxHeader';
import InputField from '../../../components/InputField/InputField';
import { useAuthUser } from '../../../context/AuthUser/AuthUserContext';

export interface LoginResponse {
    user: IUser;
    token: string;
}

const LoginForm: FunctionComponent = () => {
    const { config } = useConfig();
    const navigate = useNavigate();
    const { autoLogin } = useAuthUser();
    const [errorStatus, setErrorStatus] = useState<number | undefined>();
    const { t } = useTranslation();

    const schema = Yup.object({
        email: Yup.string()
            .email(t('Common:INPUT_ERROR_EMAIL_INVALID'))
            .required(t('Common:INPUT_ERROR_EMAIL_REQUIRED'))
            .max(255, t('Common:INPUT_ERROR_EMAIL_MAX', { max: 255 })),
        password: Yup.string().required(t('Common:INPUT_ERROR_PASSWORD_REQUIRED')),
    });

    const notify = () => toast.success(t('Common:TOAST_LOGIN_SUCCESS'));
    const notifyError = (error: string) => toast.error(t(error));

    return (
        <Formik
            initialValues={{ email: '', password: '' }}
            validationSchema={schema}
            validateOnBlur
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);

                Axios.post(`${config.baseUrl}/login`, values)
                    .then((response) => {
                        setSubmitting(false);
                        setErrorStatus(undefined);

                        const loginResponse: LoginResponse = response.data;

                        if (loginResponse) {
                            const token = loginResponse.token;
                            setLocalStorageToken(token);
                            autoLogin(token);

                            Axios.defaults.headers.common = { Authorization: `Bearer ${loginResponse.token}` };

                            notify();
                            navigate('/', { replace: true });
                        }
                    })
                    .catch((error) => {
                        const { status } = error?.response as {
                            status: number;
                            statusText: string;
                            data: any;
                        };

                        setErrorStatus(status);

                        setSubmitting(false);

                        const errorMessage =
                            errorStatus === 403 ? t('Common:ACCOUNT_INACTIVE_MESSAGE') : t('Auth:INPUT_ERROR_CREDENTIAL_MISMATCH');
                        notifyError(errorMessage);
                    });
            }}
        >
            {(props) => {
                const { isSubmitting, isValid, dirty, handleSubmit } = props;
                const disabledSubmit = isSubmitting || !(isValid && dirty);

                return (
                    <form
                        className={classes.Form}
                        noValidate={true}
                        onSubmit={handleSubmit}
                    >
                        <div className={`${classes.Login} Box`}>
                            <div className={classes.LoginContent}>
                                <BoxHeader
                                    title={t('Common:ADMIN_WELCOME_HEADER')}
                                    subtitle={t('Common:ADMIN_WELCOME_SUBTITLE')}
                                ></BoxHeader>

                                <div className={`${classes.LoginForm}`}>
                                    <InputField
                                        id="email"
                                        name="email"
                                        type="email"
                                        label="Email"
                                        autoComplete="username"
                                        required
                                        autoFocus
                                        autoCorrect="off"
                                        autoCapitalize="none"
                                        placeholder={t('Common:EMAIL_PLACEHOLDER')}
                                    />

                                    <InputField
                                        id="password"
                                        name="password"
                                        type="password"
                                        label="Password"
                                        autoComplete="current-password"
                                        required
                                        placeholder={t('Common:PASSWORD_PLACEHOLDER')}
                                    />

                                    <button
                                        className={`${classes.submitButton} btn btn-flavour btn-flavour--alert`}
                                        type="submit"
                                        disabled={disabledSubmit}
                                    >
                                        {isSubmitting && (
                                            <FontAwesomeIcon
                                                icon={faCircleNotch}
                                                spin={true}
                                            />
                                        )}
                                        {t('Common:BUTTON_LOGIN')}
                                    </button>

                                    <NavLink
                                        className={`${classes.NavLink}`}
                                        to="/password/request"
                                    >
                                        {t('Common:FORGOT_PASSWORD')}
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </form>
                );
            }}
        </Formik>
    );
};

export default LoginForm;
