import React, { FunctionComponent } from 'react';
import classes from './Header.module.scss';
import Navigation from './Navigation/Navigation';

const Header: FunctionComponent = () => {
    return (
        <header className={classes.Header}>
            <Navigation></Navigation>
        </header>
    );
};

export default Header;
