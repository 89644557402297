import { IUser } from './User';

export type AuthUser = {
    authChecked: boolean;
    isLoggedIn: boolean;
    user?: IUser;
};

export const defaultAuthUser = {
    authChecked: false,
    isLoggedIn: false,
    user: undefined,
};
