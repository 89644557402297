import { faArrowTurnRight, faMapLocationDot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import Popup from 'reactjs-popup';
import Button from '../../../../components/Button/Button';
import ExtendedMuiList from '../../../../components/ExtendedMuiList/ExtendedMuiList';
import { useConfig } from '../../../../context/ConfigContext';
import useAxiosFetch from '../../../../hooks/useAxiosFetch';
import { IQuest } from '../../../../models/Quest';
import { Tenant } from '../../../../models/Tenant';
import Axios from '../../../../utils/axiosInstance';
import classes from './QuestsList.module.scss';
import TenantsList from './TenantsList/TenantsList';

interface QuestsListProps {
    owner: Tenant;
}

const QuestsList: FunctionComponent<QuestsListProps> = ({ owner }) => {
    const { config } = useConfig();
    const { t } = useTranslation();

    const { data: dataQuests, loading: dataQuestsLoading } = useAxiosFetch<IQuest[]>({
        url: `${owner.id}/quests`,
        method: 'GET',
    });

    const [quests, setQuests] = useState<IQuest[]>([]);
    const [questToMove, setQuestToMove] = useState<IQuest>();

    const notifyMoved = (from: string, to: string, title: string) =>
        toast.success(t('Common:TOAST_QUEST_MOVED_TO_TENANT_SUCCESS', { from, to, title }));
    const notifyNotMoved = () => toast.error(t('Common:TOAST_QUEST_MOVED_TO_TENANT_FAILED'));

    const [openMove, setOpenMove] = useState(false);
    const closeModalMove = () => {
        setOpenMove(false);
        setQuestToMove(undefined);
    };

    useEffect(() => {
        if (dataQuests) {
            setQuests(dataQuests);
        }
    }, [dataQuests]);

    const onMoveQuestClickHandler = (quest: IQuest) => {
        if (questToMove !== undefined) return;

        setQuestToMove(quest);
        setOpenMove(true);
    };

    const onMoveQuestHandler = (targetTenant: Tenant) => {
        if (questToMove === undefined) return;

        const data = {
            tenant_id: targetTenant.id,
            quest_id: questToMove?.id,
        };

        Axios.post(`${config.baseUrl}/${owner.id}/quests`, data)
            .then(() => {
                notifyMoved(owner?.name || '', targetTenant.name, questToMove.title);
            })
            .catch(() => {
                notifyNotMoved();
            });

        setQuestToMove(undefined);
        closeModalMove();
    };

    const renderQuestItem = (quest: IQuest) => {
        return (
            <div
                key={`${quest.id}`}
                className={`${classes.QuestItem}`}
            >
                <div className={classes.QuestTitle}>
                    <FontAwesomeIcon icon={faMapLocationDot} />
                    {quest.title}
                </div>
                <Button
                    text=""
                    icon={<FontAwesomeIcon icon={faArrowTurnRight} />}
                    onClick={() => onMoveQuestClickHandler(quest)}
                />
            </div>
        );
    };

    const filterDataBySearch = (searchValues: string[]) => {
        // console.log(items);
        return quests.filter((item) => {
            // Specific data / array to searchable string
            const name = item.title;
            //
            const searchableValues = { name };
            const searchableValuesToString = Object.values(searchableValues);
            ///
            const searched = searchValues.every((searchValue) => {
                return searchableValuesToString.some((item) => item.toLowerCase().includes(searchValue.toLowerCase()));
            });

            return searched;
        });
    };

    return (
        <div className={classes.QuestsListContainer}>
            <h3 className={classes.QuestListHeader}>{t('Common:TENANT_QUEST_LIST_HEADER')}</h3>

            <ExtendedMuiList
                loading={dataQuestsLoading}
                filterDataBySearch={filterDataBySearch}
                searchInputLabel={t('Common:QUESTS_SEARCH_INPUT_LABEL')}
                items={quests}
                renderItem={renderQuestItem}
                noItemsMessage={t('Common:LIST_MESSAGE_NO_QUESTS')}
                noItemsFilteredMessage={t('Common:LIST_MESSAGE_NO_FILTERED_QUESTS')}
                itemsListClasses={classes.QuestsList}
            />

            <Popup
                open={openMove}
                closeOnDocumentClick
                onClose={closeModalMove}
            >
                <TenantsList
                    excludeId={owner.id}
                    onMoveQuestHandler={onMoveQuestHandler}
                />
            </Popup>
        </div>
    );
};

export default QuestsList;
