import { faBars, faHouseChimneyUser, faHouseUser, faRightFromBracket, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, DialogContent } from '@mui/material';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { useAuthUser } from '../../../context/AuthUser/AuthUserContext';
import { useDisableBodyScroll } from '../../../hooks/useDisableBodyScroll';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import { IDropDownSection, ItemLocation, ItemType } from '../../../models/Dropdown';
import Button from '../../Button/Button';
import DropDown from '../../DropDown/DropDown';
import Logo from '../../Logo/Logo';
import NavButton from './NavButton/NavButton';
import NavDropDown from './NavDropDown/NavDropDown';
import classes from './Navigation.module.scss';
import { Transition } from './Transition';

const Navigation: FunctionComponent = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { auth, onSignOutClick } = useAuthUser();
    const { t } = useTranslation();
    const isDesktopMediaQuery = useMediaQuery('(min-width: 576px)');

    const [dropDownItems, setDropDownItems] = useState<IDropDownSection[]>([]);

    useEffect(() => {
        if (auth && auth.isLoggedIn) {
            const sections: IDropDownSection[] = [
                {
                    id: 0,
                    value: t('Common:NAV_LINK_MANAGEMENT'),
                    type: ItemType.Section,
                    items: [
                        {
                            id: 2,
                            value: t('Common:NAV_LINK_MANAGEMENT_TENANTS'),
                            type: ItemType.Link,
                            link: 'home',
                            icon: faHouseChimneyUser,
                        },
                    ],
                },
                {
                    id: 1,
                    value: '',
                    type: ItemType.Section,
                    items: [
                        {
                            id: 0,
                            value: t('Common:SIGN_OUT'),
                            type: ItemType.Button,
                            location: ItemLocation.End,
                            onClick: () =>
                                onSignOutClick(() => {
                                    navigate('/login', { replace: true });
                                }),
                            icon: faRightFromBracket,
                        },
                    ],
                },
            ];

            setDropDownItems(sections.filter((section) => section.items.length > 0));
        }
    }, [auth, onSignOutClick, t]);

    const onHomeClickHandler = () => {
        navigate('/', { replace: true });
    };

    const [open, setOpen] = useState(false);
    const closeModal = () => {
        console.log(open);
        setOpen(false);
    };
    useDisableBodyScroll(open);

    const desktopNavigation = (
        <div className={classes.NavigationGroup}>
            <NavDropDown items={dropDownItems}></NavDropDown>
        </div>
    );

    const mobileNavigation = (
        <div className={classes.NavigationGroup}>
            <Button
                className={classes.NavigationButton}
                text={``}
                icon={<FontAwesomeIcon icon={faBars} />}
                onClick={() => {
                    console.log('click');
                    setOpen((o) => !o);
                }}
            ></Button>
        </div>
    );

    const navigationPopup = (
        <Dialog
            fullScreen
            open={open && !isDesktopMediaQuery}
            onClose={closeModal}
            TransitionComponent={Transition}
            PaperProps={{
                variant: 'navigation',
            }}
        >
            <DialogContent className={classes.NavigationDialogContent}>
                <div className={`${classes.NavigationPopup}`}>
                    <div className={`${classes.NavigationTop}`}>
                        <Button
                            className={classes.ClosePopupButton}
                            text={``}
                            icon={<FontAwesomeIcon icon={faTimes} />}
                            onClick={closeModal}
                        ></Button>
                    </div>

                    <DropDown
                        items={dropDownItems}
                        isOpen={true}
                        isDesktop={isDesktopMediaQuery}
                        onClose={closeModal}
                    ></DropDown>
                </div>
            </DialogContent>
        </Dialog>
    );

    return (
        <nav className={classes.Navigation}>
            <ul className={classes.NavList}>
                <li>
                    {location.pathname === '/home' ||
                    location.pathname === '/login' ||
                    location.pathname.includes('/invite') ||
                    location.pathname.includes('/password') ? (
                        <button
                            className={`btn nav-button ${classes.LogoButton} ${classes.Logo}`}
                            onClick={onHomeClickHandler}
                        >
                            <Logo />
                        </button>
                    ) : (
                        <NavButton
                            to="/home"
                            label={isDesktopMediaQuery ? t('Common:NAV_LINK_HOME_LABEL') : ''}
                            onClick={closeModal}
                        >
                            <FontAwesomeIcon
                                className={classes.HomeIcon}
                                icon={faHouseUser}
                            />
                        </NavButton>
                    )}
                </li>
                {auth.isLoggedIn && (isDesktopMediaQuery ? desktopNavigation : mobileNavigation)}
            </ul>
            {navigationPopup}
        </nav>
    );
};

export default Navigation;
