import { faHouseChimneyUser, faMapLocationDot, faUser as faSolidUser } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/Button/Button';
import ExtendedMuiList from '../../../../components/ExtendedMuiList/ExtendedMuiList';
import NewTenantDialog from '../../../../components/NewTenantDialog/NewTenantDialog';
import { BaseTenant, Tenant } from '../../../../models/Tenant';
import TenantItem from './TenantItem/TenantItem';
import classes from './Tenants.module.scss';

interface TenantsProps {
    tenants: Tenant[];
    loading: boolean;
    onTenantsUpdateHandler: (tenants: Tenant[]) => void;
}

const Tenants: FunctionComponent<TenantsProps> = ({ tenants, loading, onTenantsUpdateHandler }) => {
    const { t } = useTranslation();

    const [openNewTenant, setOpenNewTenant] = React.useState(false);
    const handleOpenNewTenant = () => {
        setOpenNewTenant(true);
    };

    const handleCloseNewTenant = () => {
        setOpenNewTenant(false);
    };

    const filterDataBySearch = (searchValues: string[]) => {
        // console.log(items);
        return tenants.filter((tenant) => {
            // Specific data / array to searchable string
            const name = tenant.name;
            //
            const searchableValues = { name };
            const searchableValuesToString = Object.values(searchableValues);
            ///
            const searched = searchValues.every((searchValue) => {
                return searchableValuesToString.some((item) => item.toLowerCase().includes(searchValue.toLowerCase()));
            });

            return searched;
        });
    };

    const renderTenantItem = (tenant: Tenant) => {
        return <TenantItem tenant={tenant} />;
    };

    const onAddTenantsHandler = () => {
        handleOpenNewTenant();
    };

    const handleNewTenant = (tenant: BaseTenant) => {
        const newTenants = [...tenants];
        newTenants.push({ ...tenant, user_count: 0, guest_count: 0, recent_logins: 0, quest_count: 0 });
        onTenantsUpdateHandler(newTenants);
        handleCloseNewTenant();
    };

    const totalQuests = _.sumBy(tenants, (tenant) => tenant.quest_count);
    const totalUsers = _.sumBy(tenants, (tenant) => tenant.user_count);
    const totalGuests = _.sumBy(tenants, (tenant) => tenant.guest_count);

    const renderListHeaders = (
        <div className={classes.TenantsListHeader}>
            {/* <h3 className={`${classes.TenantsListHeaderItem} ${classes.TenantListId}`}>{t('Common:ID_LABEL')}</h3> */}
            <h3 className={`${classes.TenantsListHeaderItem} ${classes.TenantListName}`}>{t('Common:NAME_LABEL')}</h3>
            <h3 className={`${classes.TenantsListHeaderItem} ${classes.TenantListUserCount}`}>
                <FontAwesomeIcon icon={faSolidUser} /> {t('Common:USER_COUNT')}
            </h3>
            <h3 className={`${classes.TenantsListHeaderItem} ${classes.TenantListUserCount}`}>
                <FontAwesomeIcon icon={faUser} /> {t('Common:GUEST_COUNT')}
            </h3>
            <h3 className={`${classes.TenantsListHeaderItem} ${classes.TenantListUserCount}`}>{t('Common:TOTAL')}</h3>
            <h3 className={`${classes.TenantsListHeaderItem} ${classes.TenantListUserCount}`}>{t('Common:RECENT_LOGINS')}</h3>

            <div className={`${classes.TenantsListHeaderItem} ${classes.TenantControls}`}></div>
        </div>
    );

    return (
        <div className={classes.Tenants}>
            <div className={`${classes.Header}`}>
                <h2 className={`h3 ${classes.Title}`}>{t('Common:TENANTS_HEADER')}</h2>
                <div className={classes.TenantsStat}>
                    <FontAwesomeIcon icon={faHouseChimneyUser}></FontAwesomeIcon>

                    <span className={classes.TenantsAmount}>{tenants.length}</span>
                </div>
                <div className={classes.TenantsUsersStats}>
                    <FontAwesomeIcon icon={faSolidUser} />
                    <span className={classes.TenantsUserCount}>{totalUsers}</span>
                </div>
                <div className={classes.TenantsUsersStats}>
                    <FontAwesomeIcon icon={faUser} />
                    <span className={classes.TenantsUserCount}>{totalGuests}</span>
                </div>
                <div className={classes.TenantsUsersStats}>
                    <FontAwesomeIcon icon={faMapLocationDot} />
                    <span className={classes.TenantsUserCount}>{totalQuests}</span>
                </div>
                <div className="flex-grow"></div>
                <Button
                    text={t('Common:BUTTON_ADD_TENANT')}
                    alt
                    border
                    onClick={onAddTenantsHandler}
                />
            </div>

            <div className={`Box ${classes.ManagementTenantsListBox}`}>
                <ExtendedMuiList
                    loading={loading}
                    filterDataBySearch={filterDataBySearch}
                    searchInputLabel={t('Common:TENANTS_SEARCH_INPUT_LABEL')}
                    noItemsMessage={t('Common:LIST_MESSAGE_NO_TENANTS')}
                    noItemsFilteredMessage={t('Common:LIST_MESSAGE_NO_FILTERED_TENANTS')}
                    items={tenants}
                    renderListHeaders={renderListHeaders}
                    renderItem={renderTenantItem}
                    itemsListContainerClasses={`${classes.TenantListContainer}`}
                    itemsListClasses={`${classes.TenantList}`}
                />
            </div>

            <NewTenantDialog
                open={openNewTenant}
                closeClickHandler={handleCloseNewTenant}
                newTenantHandler={handleNewTenant}
            ></NewTenantDialog>
        </div>
    );
};

export default Tenants;
