import { AuthUser } from '../../models/Auth';
import { IUser } from '../../models/User';
import { createCtx } from '../createCtx';

export type AuthUserContextType = {
    auth: AuthUser;
    user?: IUser;
    setAuthUser: (authUser: AuthUser) => void;
    setUser: (user: IUser) => void;
    checkSession: () => void;
    autoLogin: (token: string) => void;
    logout: () => void;
    onSignOutClick: (callback?: () => void) => Promise<void>;
};

export const [useAuthUser, CtxProvider] = createCtx<AuthUserContextType>();
