import React, { FunctionComponent, ReactNode, useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { IUser } from '../models/User';
import Axios from '../utils/axiosInstance';
import { getLocalStorageUser } from '../utils/flavourstorage';
import { Auth, CtxProvider } from './AuthContext';

type Props = {
    children?: ReactNode;
};

export const AuthProvider: FunctionComponent<Props> = ({ children }) => {
    const defaultAuth: Auth = { isLoggedIn: false, currentUser: undefined };
    const [auth, setAuth] = useState<Auth>(defaultAuth);
    const location = useLocation();
    const { t } = useTranslation();

    const checkSession = useCallback(() => {
        const user = loggedInUser();
        if (!user) {
            setAuth({ isLoggedIn: false, currentUser: undefined });
        } else {
            autoLogin(user);
        }
    }, []);

    useEffect(() => {
        if (location) {
            checkSession();
        }
    }, [location, checkSession, auth.isLoggedIn]);

    const loggedInUser = () => {
        const localUser = getLocalStorageUser();

        if (!localUser) {
            return null;
        } else {
            const user: IUser = JSON.parse(localUser);
            return user;
        }
    };

    const autoLogin = (user: IUser) => {
        // Set headers with user access token
        Axios.defaults.headers.common = { Authorization: `Bearer ${user.token}` };
        setAuth({ isLoggedIn: true, currentUser: user });
    };

    const logout = () => {
        setAuth({ isLoggedIn: false, currentUser: undefined });
        toast.success(t('Common:TOAST_LOGOUT_SUCCESS'));
    };

    return <CtxProvider value={{ auth, setAuth, checkSession, autoLogin, logout }}>{children}</CtxProvider>;
};
