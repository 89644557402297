import React from 'react';
import { FunctionComponentWithChildren } from '../../../../models/React';
import classes from './NavButton.module.scss';
import { NavLink } from 'react-router-dom';

interface IProps {
    to: string;
    label?: string;
    onClick?: () => void;
}

const NavButton: FunctionComponentWithChildren<IProps> = (props) => {
    return (
        <NavLink
            className={(isActive) => {
                const linkClasses = [classes.NavButton];
                if (isActive) linkClasses.push(classes.active);

                return linkClasses.join(' ').concat('nav-link btn btn-flavour btn-flavour--round ');
            }}
            to={props.to}
            onClick={() => props.onClick?.()}
        >
            {props.children}
            <label className={classes.Label}>{props.label}</label>
        </NavLink>
    );
};

export default NavButton;
