import { IUser } from '../models/User';
import { getSubdomain } from './domain';

export const setLocalStorageToken = (token: string): void => {
    const tenantUser = `${getSubdomain()}_token`;
    localStorage.setItem(tenantUser, token);
};

export const getLocalStorageToken = (): string | null => {
    const tenantUser = `${getSubdomain()}_token`;
    return localStorage.getItem(tenantUser);
};

export const setLocalStorageUser = (user: IUser): void => {
    const tenantUser = `${getSubdomain()}_user`;
    localStorage.setItem(tenantUser, JSON.stringify(user));
};

export const getLocalStorageUser = (): string | null => {
    const tenantUser = `${getSubdomain()}_user`;
    return localStorage.getItem(tenantUser);
};
