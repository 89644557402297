import { faTrashAlt, faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormControl, Input } from '@mui/material';
import debounce from 'lodash-es/debounce';
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { toast } from 'react-hot-toast';
import Button from '../../../../components/Button/Button';
import { useConfig } from '../../../../context/ConfigContext';
import { Tenant } from '../../../../models/Tenant';
import Axios from '../../../../utils/axiosInstance';
import classes from './TenantItem.module.scss';

interface TenantItemProps {
    tenant: Tenant;
    onUpdateTenantHandler: (tenant: Tenant) => void;
    onDeleteTenantHandler: (tenant: Tenant) => void;
}

const TenantItem: FunctionComponent<TenantItemProps> = ({
    tenant,
    // roles,
    // onDeactivateTenantHandler,
    // onActivateTenantHandler,
    onUpdateTenantHandler,
    onDeleteTenantHandler,
}) => {
    const { config } = useConfig();
    const [name, setName] = useState(tenant.name);

    const notifyUpdateError = () => toast.error('Could not update tenant');

    const updateTenant = useCallback(
        (updateData: Partial<Tenant>) => {
            return Axios.put(`${config.baseUrl}/tenants/${tenant.id}`, updateData)
                .then((response) => {
                    const newTenant: Tenant = response.data;
                    onUpdateTenantHandler(newTenant);
                })
                .catch((error) => {
                    notifyUpdateError();
                });
        },
        [config.baseUrl, tenant.id]
    );

    const debouncedUpdateHandler = useMemo(
        () =>
            debounce((value) => {
                updateTenant(value);
            }, 500),

        [updateTenant]
    );

    const handleNameChange = (event) => {
        setName(event.target.value);

        debouncedUpdateHandler({ name: event.target.value });
    };

    const onVisitHandler = () => {
        window.open(
            `${process.env.REACT_APP_URL_SCHEME}${tenant.id}${process.env.REACT_APP_URL_SLD_SUFFIX || ''}.${
                process.env.REACT_APP_URL_TLD
            }/app`
        );
    };

    return (
        <div className={classes.TenantItem}>
            <div className={`${classes.TenantsListItem} ${classes.TenantListId} wrap-text`}>{tenant.id}</div>
            <div className={`${classes.TenantsListItem} ${classes.TenantListName} wrap-text`}>
                <FormControl variant="standard">
                    <Input
                        name="name"
                        id="component-simple"
                        value={name}
                        onChange={handleNameChange}
                        // onBlur={handleNameChange}
                    />
                </FormControl>
            </div>

            <div className={classes.TenantControls}>
                <Button
                    onClick={() => {
                        onVisitHandler();
                    }}
                    icon={<FontAwesomeIcon icon={faUpRightFromSquare} />}
                />
                <Button
                    text=""
                    danger
                    onClick={() => {
                        onDeleteTenantHandler(tenant);
                    }}
                    icon={<FontAwesomeIcon icon={faTrashAlt} />}
                />
            </div>
        </div>
    );
};

export default TenantItem;
