import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { CSSProperties } from "react";

export enum ItemType {
    Link = 'link',
    Button = 'button',
    Section = 'section',
}

export enum ItemLocation {
    Start = 'start',
    Mid = 'mid',
    End = 'end',
}

export interface IDropDownItem {
    id: number;
    value: string;
    type: ItemType;
    location?: ItemLocation;
    icon?: IconDefinition;
    link?: string;
    button?: JSX.Element;
    onClick?: () => void;
};

export interface IDropDownSection {
    id: number;
    value: string;
    type: ItemType;
    items: IDropDownItem[];
};

export interface IDropDownProps {
    items: IDropDownSection[];
    isDesktop?: boolean;
    onClose?: () => void;
    isOpen: boolean;
    style?: CSSProperties;
}