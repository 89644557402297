import { Formik } from 'formik';
import React, { FunctionComponent } from 'react';
import Axios from '../../../utils/axiosInstance';
import * as Yup from 'yup';
import classes from './PasswordReset.module.scss';
import { useNavigate, useParams } from 'react-router';
import { useConfig } from '../../../context/ConfigContext';
import { useTranslation } from 'react-i18next';
import BoxHeader from '../../../components/BoxHeader/BoxHeader';
import toast from 'react-hot-toast';
import InputField from '../../../components/InputField/InputField';

type ParamTypes = {
    token: string;
};

const PasswordReset: FunctionComponent = () => {
    const { config } = useConfig();
    const navigate = useNavigate();
    const { token } = useParams<ParamTypes>();
    const { t } = useTranslation();

    const schema = Yup.object({
        password: Yup.string()
            .required(t('Common:INPUT_ERROR_PASSWORD_REQUIRED'))
            .min(8, t('Common:INPUT_ERROR_PASSWORD_MIN', { min: 8 }))
            .max(255, t('Common:INPUT_ERROR_PASSWORD_MAX', { max: 255 })),
        password_confirmation: Yup.string().when('password', {
            is: (val) => val && val.length > 0,
            then: Yup.string()
                .oneOf([Yup.ref('password')], t('Common:INPUT_ERROR_PASSWORD_CONFIRMATION'))
                .required(t('Common:INPUT_ERROR_PASSWORD_CONFIRMATION')),
        }),
        email: Yup.string().required(t('Common:INPUT_ERROR_EMAIL_REQUIRED')),
    });

    const toLogin = () => {
        // history.replace('/login');
        navigate('login', { replace: true });
    };

    const notify = () => toast.success(t('Auth:RETURN_TO_LOGIN'));
    const notifyError = () => toast.error(t('Auth:PASSWORD_RESET_ERROR'));
    const form = (
        <Formik
            initialValues={{
                password: '',
                password_confirmation: '',
                token: token,
                email: '',
            }}
            validationSchema={schema}
            onSubmit={async (values, { setSubmitting }) => {
                setSubmitting(true);

                try {
                    await Axios.post(`${config.baseUrl}/password/reset`, values);

                    setSubmitting(false);

                    notify();
                    toLogin();
                } catch (error) {
                    setSubmitting(false);
                    notifyError();
                }
            }}
        >
            {(formikProps) => {
                const { isSubmitting, isValid, dirty, handleSubmit } = formikProps;

                const disabledSubmit = isSubmitting || !(isValid && dirty);
                // console.log(isSubmitting, isValid, dirty, disabledSubmit);

                return (
                    <form
                        noValidate={true}
                        onSubmit={handleSubmit}
                        className={classes.form}
                    >
                        <div className={`${classes.PasswordResetForm} Box`}>
                            <BoxHeader
                                title={t('Auth:PASSWORD_RESET_TITLE')}
                                subtitle={t('Auth:PASSWORD_RESET_SUBTITLE')}
                            ></BoxHeader>
                            <div className={classes.formGroup}>
                                <InputField
                                    id="email"
                                    name="email"
                                    type="email"
                                    label={t('Common:EMAIL_LABEL')}
                                    required
                                    aria-describedby="email"
                                    placeholder={t('Common:EMAIL_PLACEHOLDER')}
                                />
                            </div>
                            <div className={classes.formGroup}>
                                <InputField
                                    id="new-password"
                                    name="password"
                                    type="password"
                                    label={t('Common:NEW_PASSWORD_LABEL')}
                                    autoComplete="new-password"
                                    required
                                    aria-describedby="password-constraints"
                                    placeholder={t('Auth:PASSWORD_EXAMPLE_PLACEHOLDER')}
                                />
                                <div className={classes.passwordHint}>{t('Auth:PASSWORD_RESET_PASSWORD_HINT')}</div>
                            </div>
                            <div className={classes.formGroup}>
                                <InputField
                                    id="password_confirmation"
                                    name="password_confirmation"
                                    type="password"
                                    label={t('Common:CONFIRMATION_PASSWORD_LABEL')}
                                    autoComplete="new-password"
                                    required
                                    placeholder={t('Auth:PASSWORD_EXAMPLE_PLACEHOLDER')}
                                />
                            </div>
                        </div>

                        <div className={`${classes.formActions} ${classes.flexGap}`}>
                            <button
                                className={`${classes.submitButton} btn btn-flavour btn-flavour--alt`}
                                type="submit"
                                disabled={disabledSubmit}
                            >
                                {t('Auth:PASSWORD_RESET_BUTTON_CONFIRM')}
                            </button>
                        </div>
                    </form>
                );
            }}
        </Formik>
    );

    return <div className={classes.PasswordReset}>{form}</div>;
};

export default PasswordReset;
