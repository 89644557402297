import React, { FunctionComponent, useEffect, useState } from 'react';
import { useClickOutsideListenerRef } from '../../../../hooks/useClickOutisdeListenerRef';
import { IDropDownSection } from '../../../../models/Dropdown';
import DropDown from '../../../DropDown/DropDown';
import NavigationIcon from '../../../Icons/NavigationIcon/NavigationIcon';
import SettingsIcon from '../../../Icons/SettingsIcon/SettingsIcon';
import classes from './NavDropDown.module.scss';

export interface INavDropDownProps {
    items: IDropDownSection[];
    onClose?: () => void;
}

const NavDropDown: FunctionComponent<INavDropDownProps> = (props) => {
    const { items } = props;
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    const onClick = () => {
        // console.log('toggle', isOpen);
        toggle();
    };

    const onClose = () => {
        // console.log('onClose isOpen:', isOpen);
        setIsOpen(false);
    };

    const openedClass = isOpen ? 'isOpen' : 'isClosed';

    const ref = useClickOutsideListenerRef(onClose);

    useEffect(() => {
        // console.log('useEffect isOpen:', isOpen);
    }, [isOpen]);

    return (
        <div
            className={`${classes.NavDropDown} ${openedClass}`}
            ref={ref}
        >
            <button
                className="btn"
                onKeyPress={() => toggle()}
                onClick={() => onClick()}
            >
                <NavigationIcon isSelected={isOpen}>
                    <SettingsIcon />
                </NavigationIcon>
            </button>
            <DropDown
                items={items}
                onClose={() => onClose()}
                isOpen={isOpen}
                isDesktop={true}
            ></DropDown>
        </div>
    );
};

export default NavDropDown;
