import { faCircleXmark, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextField, InputAdornment, IconButton, TablePagination } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import TablePaginationActions from '../Pagination/TablePaginationActions/TablePaginationActions';
import usePagination from '../Pagination/usePagination';
import classes from './ExtendedMuiList.module.scss';

export interface ExtendedMuiListProps<T> {
    items: T[];
    noItemsMessage: string;
    noItemsFilteredMessage: string;
    renderItem: (item: T) => JSX.Element;
    renderListHeaders?: JSX.Element;
    loading: boolean;
    itemsListContainerClasses?: string;
    itemsListClasses?: string;
    searchInputLabel?: string;
    searchAutoFocus?: boolean;
    filterDataBySearch?: (searchValues: string[]) => T[];
    defaultRowsPerPage?: number;
    defaultEnabled?: boolean;
}

const ExtendedMuiList = <T,>(props: ExtendedMuiListProps<T>) => {
    // SEARCH
    const [filteredResults, setFilteredResults] = useState<T[]>([]);
    const [searchInput, setSearchInput] = useState('');
    const searchInputDelay = 300;
    const searchInputRef = useRef<HTMLInputElement>(null);

    // PAGINATION
    const { page, handleChangePage, rowsPerPage, handleChangeRowsPerPage } = usePagination(props.defaultRowsPerPage || 10);

    const searchedItems = searchInput.length >= 1 && props.filterDataBySearch ? filteredResults : props.items;
    const itemsToMap = rowsPerPage > 0 ? searchedItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : [];
    const itemsList = itemsToMap.map((item, index) => {
        const muiListItem = (
            <div
                key={`listitem_${index}`}
                className={classes.MuiListItem}
            >
                {props.renderItem(item)}
            </div>
        );
        return muiListItem;
    });

    useEffect(() => {
        if (searchInput !== '') {
            searchItems(searchInput);
        }
    }, [props.items]);

    const searchItems = useCallback(
        (searchValue: string) => {
            if (!props.filterDataBySearch) return;

            setSearchInput(searchValue);

            const searchValues = searchValue.split(' ').filter((item) => item !== '');

            if (searchValue !== '') {
                const filteredData = props.filterDataBySearch(searchValues);
                setFilteredResults(filteredData);
            } else {
                setFilteredResults(props.items);
            }

            handleChangePage(null, 0);
        },
        [props]
    );

    const clearSearch = () => {
        setSearchInput('');
        setFilteredResults(props.items);
        searchInputRef.current?.focus();
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            if (searchInput) {
                searchItems(searchInput);
            }
        }, searchInputDelay);

        return () => clearTimeout(timer);
    }, [searchInput]);

    return (
        <div className={`${classes.ExtendedMuiListContainer} ${props.itemsListContainerClasses}`}>
            {props.filterDataBySearch && props.defaultEnabled !== false && (
                <TextField
                    inputRef={searchInputRef}
                    id="standard-search"
                    label={props.searchInputLabel}
                    type="search"
                    value={searchInput}
                    autoFocus={props.searchAutoFocus}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <FontAwesomeIcon icon={faMagnifyingGlass} />
                            </InputAdornment>
                        ),
                        endAdornment: searchInput.length > 0 && (
                            <IconButton onClick={clearSearch}>
                                <FontAwesomeIcon icon={faCircleXmark} />
                            </IconButton>
                        ),
                    }}
                    onChange={(e) => setSearchInput(e.target.value)}
                />
            )}

            <div className={`${classes.ExtendedMuiItemListContainer}`}>
                {props.renderListHeaders && props.renderListHeaders}

                {props.loading && (
                    <div className={classes.Loader}>
                        <div className="spinner card Box"></div>
                    </div>
                )}

                {!props.loading && (
                    <div
                        className={`${classes.ExtendedMuiItemList} ${props.itemsListClasses} ${
                            props.items.length > 0 && itemsToMap.length > 0 ? '' : classes.NoScroll
                        }`}
                    >
                        {props.items.length > 0 ? (itemsToMap.length > 0 ? itemsList : props.noItemsFilteredMessage) : props.noItemsMessage}
                    </div>
                )}
            </div>

            {!props.loading && props.defaultEnabled !== false && (
                <table>
                    <tbody>
                        <tr>
                            <TablePagination
                                className={classes.TablePagination}
                                rowsPerPageOptions={[5, 10, 25, 50]}
                                count={searchedItems.length}
                                page={page}
                                onPageChange={(event, newPage) => handleChangePage(event, newPage)}
                                rowsPerPage={rowsPerPage}
                                onRowsPerPageChange={(event) => handleChangeRowsPerPage(event)}
                                ActionsComponent={TablePaginationActions}
                            />
                        </tr>
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default ExtendedMuiList;
