import { Formik } from 'formik';
import React, { FunctionComponent, useState } from 'react';
import Axios from '../../../utils/axiosInstance';
import * as Yup from 'yup';
import classes from './PasswordRequest.module.scss';
import EmailSend from '../EmailSend/EmailSend';
import { useConfig } from '../../../context/ConfigContext';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import BoxHeader from '../../../components/BoxHeader/BoxHeader';
import { TextField } from '@mui/material';
import Button from '../../../components/Button/Button';
import toast from 'react-hot-toast';

const PasswordRequest: FunctionComponent = () => {
    const { config } = useConfig();
    const navigate = useNavigate();
    const [isSubmitted, setIsSubmitted] = useState(false);
    const { t } = useTranslation();

    const cancelHandler = () => {
        navigate('login', { replace: true });
    };

    const schema = Yup.object({
        email: Yup.string()
            .email(t('Common:INPUT_ERROR_EMAIL_INVALID'))
            .required(t('Common:INPUT_ERROR_EMAIL_REQUIRED'))
            .max(255, t('Common:INPUT_ERROR_EMAIL_MAX', { max: 255 })),
    });

    const notifyError = () => toast.error(t('Auth:PASSWORD_RECOVER_ERROR'));

    const form = (
        <Formik
            initialValues={{ email: '' }}
            validationSchema={schema}
            onSubmit={async (values, { setSubmitting }) => {
                setIsSubmitted(true);
                await Axios.post(`${config.baseUrl}/password/email`, values)
                    .then(() => {
                        setIsSubmitted(true);
                    })
                    .catch(() => notifyError());

                setSubmitting(false);
            }}
        >
            {(props) => {
                const { values, touched, errors, isSubmitting, isValid, dirty, handleChange, handleBlur, handleSubmit } = props;

                const disabledSubmit = isSubmitting || !(isValid && dirty);

                return (
                    <form
                        noValidate={true}
                        onSubmit={handleSubmit}
                    >
                        <div className={classes.PasswordRequestForm}>
                            <div className={`Box`}>
                                <BoxHeader
                                    title={t('Auth:PASSWORD_REQUEST_TITLE')}
                                    subtitle={t('Auth:PASSWORD_REQUEST_INTRODUCTION')}
                                ></BoxHeader>

                                <div className={classes.formGroup}>
                                    <TextField
                                        margin="dense"
                                        id="email"
                                        name="email"
                                        label={t('Common:EMAIL_LABEL')}
                                        type="email"
                                        fullWidth
                                        variant="standard"
                                        autoComplete="username"
                                        // focused
                                        required
                                        autoFocus
                                        autoCorrect="off"
                                        autoCapitalize="none"
                                        placeholder={t('Common:EMAIL_PLACEHOLDER')}
                                        value={values.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={touched.email && Boolean(errors.email)}
                                        helperText={touched.email && errors.email}
                                    />
                                </div>
                            </div>
                            <div className={`${classes.formActions}`}>
                                <Button
                                    type="submit"
                                    text={t('Auth:PASSWORD_REQUEST_BUTTON_RECOVER')}
                                    disabled={disabledSubmit}
                                />
                                <Button
                                    type="button"
                                    onClick={cancelHandler}
                                    text={t('Common:BUTTON_CANCEL')}
                                    alt
                                    border
                                />
                            </div>
                        </div>
                    </form>
                );
            }}
        </Formik>
    );

    const emailSend = <EmailSend />;

    return (
        <div className={classes.PasswordRequest}>
            <div className={classes.PasswordRequest}>{isSubmitted ? emailSend : form}</div>
        </div>
    );
};

export default PasswordRequest;
