import React, { FunctionComponent, useCallback, useRef } from 'react';
import classes from './EmailInput.module.scss';
import Tags from '@yaireo/tagify/dist/react.tagify';
import { useTranslation } from 'react-i18next';
import '@yaireo/tagify/dist/tagify.css';
import { useFormikContext } from 'formik';
import { TagData, TagifySettings } from '@yaireo/tagify';
import { IFormFields } from '../NewTenantDialog';

interface EmailInputProps {}

const EmailInput: FunctionComponent<EmailInputProps> = () => {
    const { t } = useTranslation();
    const tagifyRef = useRef();
    const { errors, touched, setFieldValue, setFieldTouched } = useFormikContext<IFormFields>();

    const settings: TagifySettings<TagData> = {
        placeholder: t('Common:MEMBER_INVITE_EMAIL_PLACEHOLDER'),
        delimiters: ',|;| ',
        editTags: {
            clicks: 1,
        },
    };

    const setEmailsField = (value) => {
        if (value) {
            const emails = value.map((tag) => tag.value);
            setFieldValue('emails', emails);
        } else {
            setEmailsField([]);
        }
    };

    const onChange = useCallback((e) => {
        setEmailsField(e.detail.tagify.value);
    }, []);

    return (
        <div className={classes.EmailInput}>
            <div className={classes.FormFieldHeader}>
                <label>{t('Common:MEMBER_INVITE_EMAILS_LABEL')}</label>
                <span>
                    Enter any e-mailadresses you wish to receive a support user invite for the new tenant. An invite is automatically sent
                    to support@herocenter.com. {t('Common:MEMBER_INVITE_EMAILS_SUBTITLE')}
                </span>
            </div>
            <Tags
                tagifyRef={tagifyRef}
                settings={settings}
                onChange={onChange}
                onBlur={() => setFieldTouched('emails', true)}
            />
            {errors.emails && touched.emails && <div className={classes.ErrorMessage}>{errors.emails}</div>}
        </div>
    );
};

export default EmailInput;
