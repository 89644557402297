import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { getLocalStorageToken } from '../../utils/flavourstorage';

type PrivateRouteProps = {
    children: JSX.Element;
};

const PrivateRoute: React.FC<PrivateRouteProps> = (props) => {
    const location = useLocation();
    const token = getLocalStorageToken();
    const condition = token !== null;

    return condition ? (
        props.children
    ) : (
        <Navigate
            to="/login"
            state={{ from: location }}
            replace
        />
    );
};
export default PrivateRoute;
